<template>
  <div>
    <div v-if="Object.keys(hub_stock).length !== 0">
      <b-modal id="hub-product-stock-consumption" :title="'Consumption - (Name: ' + hub_stock.name + ' ,Unit: ' + hub_stock.unit + ')'" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="stock_amount" class="col-form-label col-form-label-sm">Available Stock</label>
              <input type="text" disabled readonly v-model="hub_stock.stock_amount" id="stock_amount" name="stock_amount" class="form-control form-control-sm"/>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="consumption_amount" class="col-form-label col-form-label-sm">Consumption Qty <span class="custom-required">*</span></label>
              <input type="number" :disabled="hub_stock.stock_amount == 0 ? true : false" v-model="hub_stock.consumption_amount" v-validate="{required: true, max: 5, regex: /^[0-9]*$/}" @change="productConsumptionAmountCheck($event.target.value)"  id="consumption_amount" name="consumption_amount" class="form-control form-control-sm" placeholder="Enter product stock consumption"/>
              <div class="invalid-feedback">Product stock consumption is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="department_id" class="col-form-label col-form-label-sm">Department  <span class="custom-required">*</span></label>
              <select v-model="hub_stock.department_id" v-validate="'required'" id="department_id" name="department_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select department</option>
                <option v-for="(department, index) in departments" :key="index" :value="department.id">{{ department.department_name }}</option>
              </select>
              <div class="invalid-feedback">Department is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="consumption_amount" class="col-form-label col-form-label-sm">Employee Name <span class="custom-required">*</span></label>
              <input type="text" v-model="hub_stock.employee_name" v-validate="{required: true, max: 150 }" id="employee_name" name="employee_name" class="form-control form-control-sm" placeholder="Enter employee name"/>
              <div class="invalid-feedback">Employee name is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12 col-sm-12 col-lg-12">
              <label for="consumption_remarks" class="col-form-label col-form-label-sm">Consumption Remarks </label>
              <textarea :disabled="hub_stock.stock_amount == 0 ? true : false" v-model="hub_stock.consumption_remarks" id="consumption_remarks" name="consumption_remarks" class="form-control form-control-sm" placeholder="Enter product stock remarks"></textarea>
            </div>
          </div>

          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" :disabled="hub_stock.stock_amount == 0 ? true : false" class="text-center mr-2" :loading="loader"  @click.prevent="update">Consumption Qty</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('hub-product-stock-consumption')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'hubStockUpdate',
  props: ['hub_stock', 'departments'],
  data() {
    return {
      validation_errors: {},
      loader: false,
      show: false,
    }
  },
  mounted() {},
  methods: {
    productConsumptionAmountCheck($event) {
      const totalConsumptionAmount = $event
      const totalStockAmount = parseFloat(this.hub_stock.stock_amount || 0)
      if (parseFloat(totalConsumptionAmount) > totalStockAmount) {
        this.$notification.error({
          message: 'Consumption stock quantity amount is crossing total available amount',
        })
        this.hub_stock.consumption_amount = ''
      }
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/hub/product-stock/consumption/update', this.hub_stock)
            .then(response => {
              this.loader = false
              this.$bvModal.hide('hub-product-stock-consumption')
              if (!response.data.error) {
                this.$emit('search')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
