<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-hover table-bordered text-center" id="hub_stock_consumption_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Product</th>
              <th>Unit</th>
              <th>Total Qty</th>
              <th>Total Consumption Qty</th>
              <th>Total Remaining Qty</th>
              <th>Threshold Limit</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(product, index) in products" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ product.name }}</td>
              <td>{{ product.unit }}</td>
              <td>{{ totalStock(product.hub_product_stock, product.hub_product_stock_consumption_sum_amount) }}</td>
              <td>{{ product.hub_product_stock_consumption_sum_amount }}</td>
              <td>
                <span :style="product.hub_product_stock ? product.hub_product_stock.stock_amount > 5 ? 'color: #339933' : 'color: red' : 'color: red' " >{{ product.hub_product_stock ? product.hub_product_stock.stock_amount : 0 }}</span>
              </td>
              <td>{{ product.hub_threshold_limit }}</td>
              <td>
                <a class="btn btn-sm btn-primary" href="javascript: void(0);" @click.prevent="edit(product), $bvModal.show('hub-product-stock-consumption')">
                  <i class="fe fe-edit mr-1"></i>Consumption Qty
                </a>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="products.length === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </div>
    <hub-stock-consumption
      :hub_stock="hub_stock"
      :departments="departments"
      @search="search"
      ref="hubStockUpdate">
    </hub-stock-consumption>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import hubStockConsumption from '@/views/consumption/modal/hubStockConsumption'
import $ from 'jquery'

export default {
  name: 'List',
  components: { Empty, hubStockConsumption },
  data() {
    return {
      departments: [],
      products: {},
      hub_stock: {},
      loading: false,
      flag: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getCode()
    this.search()
  },
  methods: {
    getCode() {
      apiClient.get('api/departments')
        .then(response => {
          this.departments = response.data.departments
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.flag = true
      this.loading = true
      apiClient.get('api/hub/product-stock/consumption')
        .then(response => {
          this.loading = false
          this.flag = true
          this.products = response.data.products
          setTimeout(function() {
            $('#hub_stock_consumption_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    totalStock(hubProductStock, hubProductStockConsumptionSumAmount) {
      const totalHubProductStock = hubProductStock ? hubProductStock.stock_amount : 0
      return totalHubProductStock + hubProductStockConsumptionSumAmount
    },
    edit(product) {
      this.show = false
      this.hub_stock = {}
      this.hub_stock.name = product.name
      this.hub_stock.unit = product.unit
      this.hub_stock.hub_product_stock_consumption_sum_amount = product.hub_product_stock_consumption_sum_amount
      this.hub_stock.stock_amount = product.hub_product_stock ? product.hub_product_stock.stock_amount : 0
      this.hub_stock.hub_product_stock_id = product.hub_product_stock ? product.hub_product_stock.id : null
      this.hub_stock.product_id = product.id
      this.hub_stock.available_quantity = product.available_quantity
    },
  },
}
</script>
